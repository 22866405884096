import AMapLoader from '@amap/amap-jsapi-loader'
import { App, reactive, watch } from 'vue'
import { AMapConfig } from '/@/constants/index'
import store from '/@/store'
import { ERouterName } from '/@/types'
import router from '/@/router'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
export function useGMapManage () {
  const state = reactive({
    aMap: null, // Map类
    map: null, // 地图对象
    mouseTool: null,
  })

  async function initMap (container: string, app:App) {
    AMapLoader.load({
      ...AMapConfig
    }).then((AMap) => {
      state.aMap = AMap
      state.map = new AMap.Map(container, {
        center: [113.943225499, 22.577673716],
        layers: [
          // 卫星
          new AMap.TileLayer.Satellite(),
          // 路网
          new AMap.TileLayer.RoadNet()
        ],
        zoom: 15
      })
      state.mouseTool = new AMap.MouseTool(state.map)
      bindFunction()
      // 挂在到全局
      app.config.globalProperties.$aMap = state.aMap
      app.config.globalProperties.$map = state.map
      app.config.globalProperties.$mouseTool = state.mouseTool
      store.state.gMapState = true
    }).catch(e => {
      console.log(e)
    })
  }

  function globalPropertiesConfig (app:App) {
    initMap('g-container', app)
  }

  // 渲染预览航线
  watch(() => store.state.thisSelectWayLine,
    data => {
      if (store.state.gMapState) {
        state.map.clearMap()

        const markerArrResult:[] = []
        data.markerArr.forEach((item: any[], index: any) => {
          markerArrResult.push(wgs84togcj02(item[0], item[1]))
        })

        const polyline = new AMap.Polyline({
          path: markerArrResult,
          isOutline: true,
          showDir: true,
          outlineColor: '#ffeeff',
          borderWeight: 3,
          strokeColor: '#00d690',
          strokeOpacity: 1,
          strokeWeight: 6,
          strokeDasharray: [10, 5],
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 50
        })
        polyline.setMap(state.map)

        const startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(36, 36),
          // 图标的取图地址
          image: 'http://82.157.253.64:8085/apis/file/onLineFile/b9ec2837f320aeb25b8e6e02483f4ecb1686212966313',
          // 图标所用图片大小
          imageSize: new AMap.Size(36, 36)
        })
        const lastMarker:AMap.Marker = {}

        data.markerArr.forEach((item, index) => {
          const ss = wgs84togcj02(item[0], item[1])

          const marker = new AMap.Marker({
            icon: startIcon,
            position: ss,
            offset: new AMap.Pixel(-13, -30)
          })

          marker.setLabel({
            direction: 'bottom',
            offset: new AMap.Pixel(-5, 0), // 设置文本标注偏移量
            content: "<div class='info'>航点" + (index + 1) + '</div>', // 设置文本标注内容
          })
          marker.setMap(state.map)
          const text = new AMap.Text({
            text: '',
            style: {
              'background-color': '#29b6f6',
              'border-color': '#e1f5fe',
              'font-size': '12px',
              color: '#FFFFF'
            }
          })
          if (lastMarker.value !== undefined && lastMarker.value !== 0) {
            const p1 = lastMarker.value.getPosition()
            const p2 = marker.getPosition()
            console.log('p1:' + p1)

            const textPos = p1.divideBy(2).add(p2.divideBy(2))
            const distance = Math.round(p1.distance(p2))
            text.setText(distance + '米')
            text.setPosition(textPos)
            text.setMap(state.map)
            lastMarker.value = marker
          } else {
            lastMarker.value = marker
          }
        })

        state.map.setFitView(null)
      }
    },
    {
      deep: true
    }
  )

  function bindFunction () {
    if (router.currentRoute.value.name === ERouterName.WAYLINEEDIT) {
      state.map.on('click', addTakePointMarker)
    } else {
      state.map.off('click', addTakePointMarker)
    }
  }

  function bindFunctionTakePoint () {
    if (router.currentRoute.value.name === ERouterName.WAYLINEEDIT) {
      state.map.on('click', addMarker)
    } else {
      state.map.off('click', addMarker)
    }
  }

  function addTakePointMarker (e) {
    const startIcon = new AMap.Icon({
      // 图标尺寸
      size: new AMap.Size(36, 36),
      // 图标的取图地址
      image: 'http://82.157.253.64:8085/apis/file/onLineFile/4b05e5e440002c77246e9d0699e264001705511242804',
      // 图标所用图片大小
      imageSize: new AMap.Size(36, 36)
    })
    const marker = new AMap.Marker({
      icon: startIcon,
      position: [e.lnglat.getLng(), e.lnglat.getLat()],
      offset: new AMap.Pixel(-13, -30),
      extData: store.state.markerArr.length
    })

    marker.setLabel({
      direction: 'bottom',
      offset: new AMap.Pixel(-5, 0), // 设置文本标注偏移量
      content: "<div class='info'>起飞点</div>", // 设置文本标注内容
    })
    marker.setMap(state.map)

    state.map.off('click', addTakePointMarker)
    state.map.on('click', addMarker)
  }

  const markerArr: any[][] = []
  const lastMarker:AMap.Marker = {}
  function addMarker (e) {
    // console.log('您在 [ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ] 的位置单击了地图！')

    const startIcon = new AMap.Icon({
      // 图标尺寸
      size: new AMap.Size(36, 36),
      // 图标的取图地址
      image: 'http://82.157.253.64:8085/apis/file/onLineFile/b9ec2837f320aeb25b8e6e02483f4ecb1686212966313',
      // 图标所用图片大小
      imageSize: new AMap.Size(36, 36)
    })
    const marker = new AMap.Marker({
      icon: startIcon,
      position: [e.lnglat.getLng(), e.lnglat.getLat()],
      offset: new AMap.Pixel(-13, -30),
      extData: store.state.markerArr.length
    })

    marker.setLabel({
      direction: 'bottom',
      offset: new AMap.Pixel(-5, 0), // 设置文本标注偏移量
      content: "<div class='info'>航点" + (store.state.pathArr.length + 1) + '</div>', // 设置文本标注内容
    })
    marker.setMap(state.map)
    const text = new AMap.Text({
      text: '',
      style: {
        'background-color': '#29b6f6',
        'border-color': '#e1f5fe',
        'font-size': '12px',
        color: '#FFFFF'
      }
    })

    if (lastMarker.value !== undefined && lastMarker.value !== 0) {
      const p1 = lastMarker.value.getPosition()
      const p2 = marker.getPosition()
      const textPos = p1.divideBy(2).add(p2.divideBy(2))
      const distance = Math.round(p1.distance(p2))
      text.setText(distance + '米')
      text.setPosition(textPos)
      text.setMap(state.map)
      store.state.totalDistance += distance
    }
    marker.on('click', function (e) {
      store.state.drawerVisible = true
      store.state.clickPointIndex = marker.getExtData()
    })
    lastMarker.value = marker

    const ss = gcj02towgs84(e.lnglat.getLng(), e.lnglat.getLat())

    store.state.markerArr.push({
      index: store.state.markerArr.length,
      position: ss,
      actionArr: []
    })
    store.state.pathArr.push([e.lnglat.getLng(), e.lnglat.getLat()])
    console.log('添加数据：' + store.state.pathArr)
    initLinePath()
  }

  function initLinePath () {
    const polyline = new AMap.Polyline({
      path: store.state.pathArr,
      isOutline: true,
      showDir: true,
      outlineColor: '#ffeeff',
      borderWeight: 3,
      strokeColor: '#00d690',
      strokeOpacity: 1,
      strokeWeight: 6,
      strokeDasharray: [10, 5],
      lineJoin: 'round',
      lineCap: 'round',
      zIndex: 50
    })
    polyline.setMap(state.map)
  }

  function getMarkerArr () {
    return markerArr
  }

  watch(() => router.currentRoute.value.name,
    (data) => {
      if (data !== ERouterName.WAYLINEEDIT) {
        state.map.off('click', addTakePointMarker)
      } else {
        state.map.on('click', addTakePointMarker)
      }
    })

  watch(() => store.state.mapCenter,
    (data) => {
      state.map.panTo(data)
    })

  return {
    globalPropertiesConfig, getMarkerArr
  }
}
