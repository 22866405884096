import store from '/@/store'
import { getRoot } from '/@/root'
import { ELocalStorageKey, EDeviceTypeName } from '/@/types'
import { getDeviceBySn } from '/@/api/manage'
import { message } from 'ant-design-vue'
import dockIcon from '/@/assets/icons/dock.png'
import rcIcon from '/@/assets/icons/rc.png'
import droneIcon from '/@/assets/icons/drone.png'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
export function deviceTsaUpdate () {
  const root = getRoot()
  let AMap = root.$aMap

  const icons = new Map([
    [EDeviceTypeName.Aircraft, droneIcon],
    [EDeviceTypeName.Gateway, rcIcon],
    [EDeviceTypeName.Dock, dockIcon]
  ])
  const markers = store.state.markerInfo.coverMap
  const paths = store.state.markerInfo.pathMap

  let trackLine = null as any
  function getTrackLineInstance () {
    if (!trackLine) {
      trackLine = new AMap.Polyline({
        map: root.$map,
        isOutline: true,
        showDir: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: '#00d690',
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round', // 线颜色
      })
    }
    return trackLine
  }

  function initIcon (type: number) {
    return new AMap.Icon({
      image: icons.get(type),
      imageSize: new AMap.Size(40, 40),
      size: new AMap.Size(40, 40)
    })
  }

  function initMarker (type: number, name: string, sn: string, lng?: number, lat?: number) {
    if (markers[sn] && markers[sn]._position.lng > 1 && lat !== undefined && lng !== undefined) {
      return
    }

    if (root.$aMap === undefined) {
      return
    }
    AMap = root.$aMap

    const ss = wgs84togcj02(lng || 0, lat || 0)
    markers[sn] = new AMap.Marker({
      position: new AMap.LngLat(ss[0], ss[1]),
      icon: initIcon(type),
      title: name,
      anchor: 'top-center',
      offset: [0, -20],
    })
    root.$map.add(markers[sn])
    root.$map.panTo([ss[0], ss[1]])
    // if (type === EDeviceTypeName.Aircraft){
    //   markers[sn].on('moving', function (e: any) {
    //     let path = paths[sn]
    //     if (!path) {
    //       paths[sn] = e.passedPath
    //       return
    //     }
    //     path.push(e.passedPath[0])
    //     path.push(e.passedPath[1])
    //     // getTrackLineInstance().setPath(path)
    //   })
    // }
  }

  function convertFrom (lng?:number, lat?:number) {
    const gps = [lng, lat]
  }

  function removeMarker (sn: string) {
    if (!markers[sn]) {
      return
    }
    root.$map.remove(markers[sn])
    getTrackLineInstance().setPath([])
    delete markers[sn]
    delete paths[sn]
  }

  function addMarker (sn: string, lng?: number, lat?: number) {
    getDeviceBySn(localStorage.getItem(ELocalStorageKey.WorkspaceId)!, sn)
      .then(data => {
        if (data.code !== 0) {
          message.error(data.message)
          return
        }

        console.log('经纬度：' + lng + ',' + lat)
        initMarker(data.data.domain, data.data.nickname, sn, lng, lat)
      })
  }

  function moveTo (sn: string, lng: number, lat: number) {
    let marker = markers[sn]
    if (!marker) {
      addMarker(sn, lng, lat)
      marker = markers[sn]
      return
    }

    const ss = wgs84togcj02(lng || 0, lat || 0)

    marker.moveTo([ss[0], ss[1]], {
      duration: 1800,
      autoRotation: true
    })
  }

  return {
    marker: markers,
    initMarker,
    removeMarker,
    moveTo
  }
}
