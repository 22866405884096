export const CURRENT_CONFIG = {

  // license
  appId: '143593', // You need to go to the development website to apply.
  appKey: '1f594aed9ce0612f87ad80b4ae5fadf', // You need to go to the development website to apply.
  appLicense: 'w8fQ/nsjbP6Juue1jWS69i5MJYalSBK8Sdf8MuyHlfdbzHZabaGOrSGvPsuVtvusErBMpqBCMkISawnV80OMwaBUWqUR8GNIswZ/zs5UqRbiv5GH3SIMdKDVkVL/3lOxYsZUby8aSUrzGAQF6gKKSVW1pX5AXTbAqUZ/IcBL6Fs=', // You need to go to the development website to apply.

  // http
  // baseURL: 'http://39.103.98.133:6789', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  baseURL: 'http://www.shadyside.cn/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // baseURL: 'http://39.103.98.133:6789', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // baseURL: 'http://192.168.0.108:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: 'ws://39.103.98.133:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  websocketURL: 'ws://www.shadyside.cn/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // websocketURL: 'ws://192.168.0.108:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://www.shadyside.cn:8080/live', // Example: 'rtmp:   //192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: '',
  rtspPassword: '',
  rtspPort: '554',
  // Agora
  agoraAPPID: '143593',
  agoraToken: '1f594aed9ce0612f87ad80b4ae5fadf',
  agoraChannel: 'w8fQ/nsjbP6Juue1jWS69i5MJYalSBK8Sdf8MuyHlfdbzHZabaGOrSGvPsuVtvusErBMpqBCMkISawnV80OMwaBUWqUR8GNIswZ/zs5UqRbiv5GH3SIMdKDVkVL/3lOxYsZUby8aSUrzGAQF6gKKSVW1pX5AXTbAqUZ/IcBL6Fs=',

  // map
  // You can apply on the AMap website.
  amapKey: '1ec1dd6592ff7fce563b047e1807913b',

}
