<template>
  <div class="demo-app">
    <router-view />
    <!-- <div class="map-wrapper">
      <GMap/>
    </div> -->
    <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index: 99; text-align: center; padding: 20px 0; color: #fff">
      <a href="https://beian.miit.gov.cn/">粤ICP备18013889号</a>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useMyStore } from './store'
import GMap from '/@/components/GMap.vue'

export default defineComponent({
  name: 'App',
  components: { GMap },

  setup () {
    const store = useMyStore()
    return {}
  }
})
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%
}
</style>
